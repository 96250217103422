<template>
  <div class="wrapper">
    <PostList
      :posts="posts"
      :stories="stories"
      :currentCustomer="getCurrentCustomer"
      :isShowSwitch="false"
      :isShowCloseIcon="false"
      :postListType="'narrow'"
      :postType="'rejected'"
      isShowComment
      isShowEditIcon
      isShowMakeChangesButton
      @postDeletedSuccessfully="deletePostHandler"
      isShowTopActions
      isShowDateCreation
      isShowDeletedIcon
      isCanEditPublishDate
      isShowPublishTime
      headerTitle="Rejected"
      @updateSchedule="updateScheduleHandler"
      isCanShowEditedLabel
      isCanShowBackupData
      isShowActionDateInFooter
      actionDateTitle="Rejected at:"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PostList from "@/components/createPost/PostList";

export default {
  name: "Approved",
  data: () => ({
    posts: [],
    stories: [],
  }),
  components: {
    PostList,
  },
  async created() {
    await this.getPosts();
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    ...mapActions("createPost", ["fetchPosts"]),
    async getPosts() {
      const data = await this.fetchPosts({
        routeName: "rejected",
        customerId: this.getCurrentCustomer.id,
        limit: 100,
      });
      this.posts = [];
      this.stories = [];
      if (data) {
        data.forEach((item) => {
          item.type === "post"
            ? this.posts.push(item)
            : this.stories.push(item);
        });
        this.posts.sort((a, b) => {
          return a.updatedAt < b.updatedAt ? 1 : -1;
        });
        this.stories.sort((a, b) => {
          return a.updatedAt < b.updatedAt ? 1 : -1;
        });
        this.$emit("updateAmountPost");
      }
    },
    deletePostHandler() {
      this.getPosts();
      this.$emit("updateAmountPost");
    },
    updateScheduleHandler(postData) {
      let searchPost =
        this.posts.find((post) => post.id === postData.postId) ||
        this.stories.find((post) => post.id === postData.postId);
      searchPost
        ? (searchPost.scheduledForStr = postData.scheduledForStr)
        : null;
    },
  },
};
</script>
